//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    rotated: {
      type: Boolean,
      default: false
    }
  }
};