import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.sort.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';
import randomProducts from '~/graphql/queries/randomProducts.gql';
export default {
  props: {
    reverseDirection: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    randomProducts: {
      query: randomProducts,
      result: function result(_ref) {
        var _this = this;

        var data = _ref.data;

        if (data.randomProducts) {
          this.randomProducts = data.randomProducts;
          this.$nextTick(function () {
            return _this.loadSwiper();
          });
        }
      },
      skip: function skip() {
        return process.server;
      }
    }
  },
  data: function data() {
    return {
      randomProducts: [],
      swiper: null
    };
  },
  computed: {
    slides: function slides() {
      return _toConsumableArray(this.randomProducts).sort(function () {
        return Math.random() > 0.5 ? 1 : -1;
      });
    }
  },
  methods: {
    loadSwiper: function loadSwiper() {
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 1.8,
        loop: true,
        speed: 1500,
        spaceBetween: 8,
        autoplay: {
          delay: 10,
          stopOnLastSlide: false
        },
        breakpoints: {
          420: {
            slidesPerView: 2
          },
          440: {
            slidesPerView: 2.2
          },
          480: {
            slidesPerView: 2.3
          },
          510: {
            slidesPerView: 2.4
          },
          540: {
            slidesPerView: 2.5
          },
          570: {
            slidesPerView: 2.6
          },
          600: {
            slidesPerView: 2.8
          },
          630: {
            slidesPerView: 3
          },
          660: {
            slidesPerView: 3.2
          },
          690: {
            slidesPerView: 3.4
          },
          720: {
            slidesPerView: 3.6
          },
          750: {
            slidesPerView: 3.7
          },
          780: {
            slidesPerView: 3.9
          },
          810: {
            slidesPerView: 4
          },
          840: {
            slidesPerView: 4.2
          },
          1000: {
            slidesPerView: 5
          },
          1100: {
            slidesPerView: 5.5
          },
          1200: {
            slidesPerView: 5.75
          },
          1300: {
            slidesPerView: 6
          },
          1350: {
            slidesPerView: 6.4
          },
          1400: {
            slidesPerView: 6.8
          },
          1450: {
            slidesPerView: 6.9
          },
          1580: {
            slidesPerView: 7.2
          }
        }
      });
    }
  }
};