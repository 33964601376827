import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    extraSmall: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styles: function styles() {
      return {
        'background-color': this.card.own_design ? this.card.color_code : 'transparent'
      };
    },
    cardImage: function cardImage() {
      var _this$card$active_ima;

      return (_this$card$active_ima = this.card.active_image) !== null && _this$card$active_ima !== void 0 && _this$card$active_ima.url ? this.card.active_image.url : require('~/assets/img/default.png');
    }
  }
};