import { render, staticRenderFns } from "./CardOverview.vue?vue&type=template&id=64c4f56e&scoped=true&"
import script from "./CardOverview.vue?vue&type=script&lang=js&"
export * from "./CardOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64c4f56e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiInfoboxInfoBoxHeader: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/Infobox/InfoBoxHeader.vue').default,UiInfoboxInfoBoxContent: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/Infobox/InfoBoxContent.vue').default,UiInfoboxInfoBox: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/ui/Infobox/InfoBox.vue').default,IconsChevronLeft: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/icons/ChevronLeft.vue').default,IconsChevronRight: require('/data/www/buyanygiftcard.com/buyanygiftcard/nuxt/components/icons/ChevronRight.vue').default})
