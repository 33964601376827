//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CardOverview',
  props: {
    cards: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    pagination: {
      type: Boolean,
      default: false
    },
    grid: {
      type: String,
      default: 'sm:grid-cols-2 lg:grid-cols-3'
    }
  },
  data: function data() {
    return {};
  }
};