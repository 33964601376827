//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'InfoBoxContent',
  props: {
    slug: {
      type: String,
      default: null
    }
  }
};