import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    expandable: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      readMoreActive: false
    };
  }
};